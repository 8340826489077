import type { ArticleContentsCategory } from '@shared/components/molecules/ArticleContents.vue';
import type { KiwiUserGetCategoriesQuery } from '@shared/gql/graphql';
import type {
  GetArticlesQueryArticleExtended,
  GetPickupArticlesQueryArticleExtended,
  GetPublicArticleDetailByPermalinkQueryArticleExtended,
} from '@shared/types/kiwiUserServer';

export async function useCategories() {
  const categoriesState = useState<KiwiUserGetCategoriesQuery['category']>(
    'categories',
    () => [],
  );

  function getCategoriesByFolderId(folder_id: number) {
    return categoriesState.value.filter(
      (category) => category.folder_id === folder_id,
    );
  }

  function getCategoryIdByName(folder_id: number, name: string) {
    return getCategoriesByFolderId(folder_id).find(
      (category) => category.name === name,
    )?.id;
  }

  function getCategoryNameById(folder_id: number, id: number) {
    return getCategoriesByFolderId(folder_id).find(
      (category) => category.id === id,
    )?.name;
  }

  function getNewsCategoryIdByName(name: string) {
    return getCategoryIdByName(100, name);
  }

  function getNewsCategoryNameById(id: number) {
    return getCategoryNameById(100, id);
  }

  function getDietqaCategoryIdByName(name: string) {
    return getCategoryIdByName(300, name);
  }

  function isPrArticle(
    article:
      | GetArticlesQueryArticleExtended
      | GetPickupArticlesQueryArticleExtended,
  ) {
    const prCategoryId = getNewsCategoryIdByName('広告');
    return article.categories.some(
      (category) => category.category_id === prCategoryId,
    );
  }

  function getArticleCategories(
    folder_id: number,
    article: GetPublicArticleDetailByPermalinkQueryArticleExtended,
  ): ArticleContentsCategory[] {
    const filteredArticleCategories = article.categories.filter(
      (articleCategory) => {
        return (
          getCategoriesByFolderId(folder_id).find(
            (category) => category.id === articleCategory.category_id,
          )?.type === 100 // NOTE: カテゴリ種別: 100=category(カテゴリ)|200=flag(フラグ)
        );
      },
    );

    return filteredArticleCategories.map((category) => {
      return {
        category_id: category.category_id,
        category_name:
          getCategoryNameById(folder_id, category.category_id) ?? '',
      };
    });
  }

  function getNewsArticleCategories(
    article: GetPublicArticleDetailByPermalinkQueryArticleExtended,
  ): ArticleContentsCategory[] {
    return getArticleCategories(100, article);
  }

  function getDietqaArticleCategories(
    article: GetPublicArticleDetailByPermalinkQueryArticleExtended,
  ): ArticleContentsCategory[] {
    return getArticleCategories(300, article);
  }

  if (!categoriesState.value.length) {
    const response = await useFetch('/api/kiwi_user/categories');
    categoriesState.value = response.data.value ?? [];
  }

  return {
    categoriesState,
    getNewsCategoryIdByName,
    getNewsCategoryNameById,
    isPrArticle,
    getNewsArticleCategories,
    getDietqaCategoryIdByName,
    getDietqaArticleCategories,
  };
}
